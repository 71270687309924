import {Component, OnInit} from '@angular/core';
import {ReduceEstabelecimentoModel} from "src/app/shared/models/estabelecimentos/Estabelecimento.model";
import {DataTitleTabela} from "src/app/shared/intefaces.model";
import {MatDialogRef} from "@angular/material/dialog";
import {EstabelecimentoService} from "src/app/shared/services/estabelecimento.service";
import {firstValueFrom} from 'rxjs';
import {StorageFiltroEstabelecimentosService} from "src/app/shared/services/localStorage/storage-filtro-estabelecimentos.service";

@Component({
    selector: 'filtro-estabs',
    templateUrl: './filtroEstabs.component.html',
    styleUrls: ['./filtroEstabs.component.scss'],
})
export class FiltroEstabsComponent implements OnInit {

    confirma1: boolean = true;
    confirma2: boolean = true;
    filtroEstabs: ReduceEstabelecimentoModel[] = [];
    estabelecimentos: ReduceEstabelecimentoModel[] = [];

    public columns: DataTitleTabela[] = [
        {title: 'Tipo', dataKeyRef: 'tipo_nome'},
        {title: 'Estabelecimento', dataKeyRef: 'nome'},
    ];

    constructor(
        private estabelecimentoService: EstabelecimentoService,
        public dialogRef: MatDialogRef<FiltroEstabsComponent>,
        private filtroEstabelecimentosService: StorageFiltroEstabelecimentosService,
    ) {
    }

    async ngOnInit() {
        await this.getEstabelecimentos();
        this.filtroEstabs = this.estabelecimentos.filter(estab => this.filtroEstabelecimentosService.get().includes(estab.codigo));
    }

    atualizaFiltros(event: ReduceEstabelecimentoModel[]) {
        this.filtroEstabs = event;
    }

    setFilterLocalStorage(buscar: boolean) {
        (buscar ? this.confirma1 = false : this.confirma2 = false);
        this.filtroEstabelecimentosService.set(buscar ? this.filtroEstabs.map(estab => estab.codigo) : []);
        location.reload();
    }

    async getEstabelecimentos() {
        try {
            this.estabelecimentos = await firstValueFrom(this.estabelecimentoService.getAllSimple$());
            this.estabelecimentos = this.estabelecimentos.map(estab => {
                let est = new ReduceEstabelecimentoModel().assign(estab);
                est.tipo_nome = est.getTipos[est.tipo];
                return est;
            });

        } catch (e) {
            console.error('Erro ao buscar listagem de estabelecimentos para filtragem geral');
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
