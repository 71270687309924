
<table-with-selection [loading]="!estabelecimentos.length"
                      [options]="estabelecimentos"
                      [selecteds]="filtroEstabs"
                      [columns]="columns"
                      title="Filtragem por estabelecimento"
                      (changeSelection)="atualizaFiltros($event)"></table-with-selection>

<div class="flex flex-col sm:flex-row gap-2.5 justify-end mt-4">

    <button mat-stroked-button matDialogClose="true" color="warn"> Cancelar </button>

    <button [loadingButton]="!confirma2"
            [disabled]="!confirma1 || !confirma2" color="warn"
            mat-raised-button (click)="setFilterLocalStorage(false)">
        Limpar Filtro
    </button>

    <button [loadingButton]="!confirma1" [disabled]="!confirma1 || !confirma2" color="primary"
            mat-raised-button (click)="setFilterLocalStorage(true)" class="text-white">
        Confirmar Filtro
    </button>

</div>
