import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BlockWithTitleComponent} from './block-with-title.component';

@NgModule({
    declarations: [
        BlockWithTitleComponent
    ],
    exports: [
        BlockWithTitleComponent
    ],
    imports: [
        CommonModule
    ]
})
export class BlockWithTitleModule {
}
