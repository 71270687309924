
<mat-toolbar color="primary" class="gap-x-2.5">

    <a [routerLink]="['/home']">
        <button mat-icon-button class="button-hover">
            <mat-icon svgIcon="icon-logo-limber"></mat-icon>
        </button>
    </a>

    <span class="spacer"></span>

    <div *ngIf="mostraFiltroEstabs">

        <button *ngIf="!contemFiltro" mat-button class="button-hover" (click)="openDialogEstabs()" matTooltip="Filtro de Estabelecimentos">
            <span class="ml-2 max-md:hidden">Estabelecimentos</span>
            <mat-icon class="m-0">filter_alt</mat-icon>
        </button>

        <button *ngIf="contemFiltro" mat-raised-button (click)="openDialogEstabs()" matTooltip="Filtro de Estabelecimentos">
            <span class="ml-2 max-md:hidden">* Estabelecimentos</span>
            <mat-icon class="m-0">filter_alt</mat-icon>
        </button>

    </div>

    <a class="no-underline" *ifHavePermissionTo="{perm: 'administrador'}" [routerLink]="['/pages/config/notificacoes']">
        <button mat-icon-button class="button-hover" matTooltip="Notificações">
            <mat-icon class="text-white" [matBadge]="qtdLogsErro()" matBadgeColor="warn">
                notifications
            </mat-icon>
        </button>
    </a>

    <a *ifHavePermissionTo="{perm: 'administrador'}" [routerLink]="['/pages/config/usuario']" [queryParams]="{id: loggedUser()?.codigo}">
        <button mat-icon-button class="button-hover" [matTooltip]="loggedUser()?.nome">
            <mat-icon>person</mat-icon>
        </button>
    </a>

    <a (click)="logout(); $event.preventDefault()" href="/auth/login">
        <button mat-icon-button class="button-hover" matTooltip="Sair">
            <mat-icon>logout</mat-icon>
        </button>
    </a>

</mat-toolbar>
