import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'popup-notificacoes',
    templateUrl: './popup-notificacoes.component.html',
    styleUrls: ['./popup-notificacoes.component.scss']
})
export class PopupNotificacoesComponent implements OnInit {
    iconSize = '50px';

    constructor(
        private dialogRef: MatDialogRef<PopupNotificacoesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            qtdLogsErro: number
        }) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {

    }
}
