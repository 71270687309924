import {Injectable} from '@angular/core';
import {CardStorage} from "src/app/shared/services/localStorage/CardStorage";

@Injectable({
    providedIn: 'root'
})
export class StorageFiltroEstabelecimentosService extends CardStorage<number[]> {
    isJson: boolean = true;
    key: string = 'filtroEstabelecimentos';
    override defaultValue = [];
}
