import {Component, signal} from '@angular/core';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {MatExpansionModule} from "@angular/material/expansion";

@Component({
    selector: 'app-chuncked-loading',
    templateUrl: './chuncked-loading.component.html',
    styleUrls: ['./chuncked-loading.component.scss'],
    standalone: true,
    imports: [
        MatProgressSpinnerModule,
        NgClass,
        NgForOf,
        NgIf,
        MatExpansionModule
    ]
})
export class ChunckedLoadingComponent {

    public timerValue = signal<number>(0);
    public interval;

    public progress: number = 0;

    public message: string = 'Processando...';

    public error: boolean = false;

    public history: string = null;
    public historyList: { message: string, error: boolean, time: number }[] = [];

    constructor() {
        this.startCounter();
    }

    private startCounter() {
        this.interval = setInterval(() => {
            this.timerValue.update((time) => ++time);
        }, 1000);
    }
    public finishCounter() {
        clearInterval(this.interval);
    }

}
