/**
 * Classe usada para armazenar informações no LocalStorage
 */
export abstract class CardStorage<T> {

    public abstract key: string;
    public abstract isJson: boolean;
    public defaultValue: any = null;

    public set(data: T) {
        let stringToSave: any = (this.isJson ? JSON.stringify(data) : data);
        localStorage.setItem(this.key, stringToSave);
    }

    public get(): T {
        let storageData = window.localStorage.getItem(this.key);
        if (!storageData)
            return this.defaultValue;

        return (this.isJson ? JSON.parse(storageData) : storageData);
    }

}
