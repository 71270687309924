import {ActivatedRouteSnapshot} from '@angular/router';
import {Injectable} from "@angular/core";
import {Role} from "src/app/shared/models/RolePerm.model";
import {Utils} from "src/app/shared/utils/utils";
import {AuthenticatedUserService} from "src/app/shared/services/store/authenticatedUser.service";

@Injectable({
    providedIn: "root"
})
export class VerificaPermissao {

    public readonly naoTemPermissao: string = 'Usuário com permissão insuficiente para visualizar o conteúdo da plataforma';

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private utils: Utils
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        return this.verify(route.data['role']);
    }

    canActivateChild(route: ActivatedRouteSnapshot): boolean {
        return this.verify(route.data['role']);
    }

    private verify(tipoPerm: Role): boolean {
        if (!tipoPerm)
            return true;

        if (tipoPerm === Role.VisualizaRelatorios) {
            const possuiPerm: boolean = this.authenticatedUserService.permissions().some(perm => perm[tipoPerm] === 2);
            if (!possuiPerm)
                this.utils.openSnackBar(this.naoTemPermissao, 'error');

            return possuiPerm;

        } else {
            return (tipoPerm ? this.authenticatedUserService.permissions().some(perm => perm[tipoPerm] === 'S') : true);
        }
    }

}
