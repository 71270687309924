import {FormGroup, FormControl, Validators} from "@angular/forms";
import {Abstract} from "src/app/shared/models/abstract";
import {Subscription} from "rxjs";
import {PermissaoEstabelecModel} from "src/app/shared/models/PermissaoEstabelec.model";

export enum TipoPermissaoRelatorio {
    PERSONALIZADO = 0,
    MODULO_PRICING = 1,
    PADRAO_CARD = 2
}

export class RelatorioV2Model extends Abstract {
    codigo: string = null;
    nome: string = null;
    link: string = null;
    aba: string = null;
    tipo_permissao: TipoPermissaoRelatorio = TipoPermissaoRelatorio.PERSONALIZADO;
    altura: number = null;
    largura: number = null;

    periodo_maximo_dias: number = null;

    ativo: boolean = true;
    estabelecimentos: PermissaoEstabelecModel[] = [];

    getForm(): {form: FormGroup<FormRelatorioV2>, sub: Subscription} {
        let form = new FormGroup<FormRelatorioV2>({
            codigo: new FormControl(this.codigo),
            nome: new FormControl(this.nome, [Validators.required]),
            link: new FormControl(this.link, [Validators.required]),
            aba: new FormControl(this.aba, [Validators.required]),
            tipo_permissao: new FormControl(this.tipo_permissao),
            altura: new FormControl(this.altura, [Validators.required]),
            largura: new FormControl(this.largura, [Validators.required]),
            periodo_maximo_dias: new FormControl(this.periodo_maximo_dias),
            ativo: new FormControl(this.ativo),
        });
        let sub = this.subscribeFormChangeToUpdateClass(form);
        return {form, sub};
    }

    static tipoPermissaoDesc = {
        [TipoPermissaoRelatorio.PERSONALIZADO]: 'Personalizado',
        [TipoPermissaoRelatorio.MODULO_PRICING]: 'Modulo Pricing',
        [TipoPermissaoRelatorio.PADRAO_CARD]: 'Padrão Card'
    }
}

export interface FormRelatorioV2 {
    codigo: FormControl<string>;
    nome: FormControl<string>;
    link: FormControl<string>;
    aba: FormControl<string>;
    tipo_permissao: FormControl<TipoPermissaoRelatorio>;
    ativo: FormControl<boolean>;
    altura: FormControl<number>;
    largura: FormControl<number>;
    periodo_maximo_dias: FormControl<number>;
}
