import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Optional, signal, ChangeDetectionStrategy, WritableSignal} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {DataTitleTabela} from "src/app/shared/intefaces.model";

@Component({
    selector: 'table-with-selection',
    templateUrl: './table-with-selection.component.html',
    styleUrls: ['./table-with-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableWithSelectionComponent<T extends Record<string, any> = object> implements OnInit, OnDestroy {

    @Input() columns: DataTitleTabela[] = [];
    options: T[] = [];
    @Input('options') set _options(value: T[]) {
        this.options = value;
        if (this.selectedBuffer) {
            for (let optionSelected of this.selectedBuffer) {
                let foundRow = this.options.find((opt) => opt[this.localizador] === optionSelected[this.localizador]);
                if (foundRow) {
                    this.selection.select(foundRow);
                }
            }
            this.selectedBuffer = undefined;
        }
        this.applyFilter(this.filtro.value);
    };
    @Input() title: string = '';
    @Input() size: 'h2' | 'h3' = 'h2';

    _selecteds = []
    selectedBuffer: T[];
    @Input() set selecteds(value: T[]) {
        this.selectedBuffer = [];
        for (let optionSelected of value) {
            let foundRow = this.options.find((opt) => opt[this.localizador] === optionSelected[this.localizador]);
            if (foundRow) {
                this.selection.select(foundRow);
            } else {
                this.selectedBuffer.push(optionSelected);
            }
        }
        this._selecteds = this.selecteds
    }
    @Optional() @Input() loading: boolean = false;

    // DEFAULT VALUE = 'codigo'
    @Input() localizador: keyof T = 'codigo';

    @Output() changeSelection: EventEmitter<T[]> = new EventEmitter<T[]>();

    public displayedColumns: string[] = ['select'];
    public selection: SelectionModel<T> = new SelectionModel<T>(true, []);
    public filtro: FormControl = new FormControl<string>(null);

    private subs: Subscription[] = [];

    public filteredData: WritableSignal<T[]> = signal(this.options)

    constructor() {}

    ngOnInit() {
        this.columns.forEach(column => this.displayedColumns.push(column.dataKeyRef));
        this.subs.push(
            this.filtro.valueChanges.subscribe((value: string) => this.applyFilter(value)),
        )
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const totalSelected = this.selection.selected.length;
        const totalRows = this.options.length;
        return totalSelected === totalRows;
    }

    isAllFilteredSelected() {
        const totalSelected = this.selection.selected.filter((rowSelected) => {
            return Object.entries(rowSelected).reduce((last, [key, val]) => `${last};${key}:${val}` , '').toLowerCase().includes(this.filtro.value.trim().toLowerCase())
        }).length;
        const totalRows = this.filteredData().length;
        return totalSelected === totalRows;
    }

    masterToggle(value: boolean) {
        if (this.filtro.value) {
            this.filteredData().forEach((row) => {
                value ? this.selection.select(row) : this.selection.deselect(row);
            });
        } else {
            this.isAllSelected() ? this.selection.clear() : this.options.forEach(row => this.selection.select(row));
        }
        this.changeSelection.emit(this.selection.selected);
    }

    toggle(row: T) {
        this.selection.toggle(row);
        this.changeSelection.emit(this.selection.selected);
    }

    applyFilter(filterValue: string) {
        this.filteredData.set(!filterValue ? this.options : this.options.filter((val) => {
            return Object.entries(val).reduce((last, [key, val]) => `${last};${key}:${val}` , '').toLowerCase().includes(filterValue.trim().toLowerCase())
        }));
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }

}
