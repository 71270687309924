import {NgModule} from '@angular/core';
import {ListaPermissoesComponent} from "./listaPermissoes.component";
import {CommonModule} from "@angular/common";
import {TitleButtonModule} from "src/app/shared/components/title-button/title-button.module";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    imports: [
        CommonModule,
        TitleButtonModule,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
    ],
    declarations: [ListaPermissoesComponent],
    exports: [ListaPermissoesComponent]
})
export class ListaPermissoesModule {
}
