import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes, provideRouter, withComponentInputBinding, RouteReuseStrategy} from "@angular/router";
import {IsNotAuthInterceptor} from "src/app/shared/interceptors/isNotAuth.interceptor";
import {AuthInterceptor} from "src/app/shared/interceptors/is-authentication.interceptor";
import {PagesComponent} from "src/app/pages/pages.component";
import {VerificaPermissao} from "src/app/shared/interceptors/permissao.interceptor";
import {NoAuthPagesComponent} from "src/app/no-auth-pages/no-auth-pages.component";
import {AppRouteReuseStrategy} from "src/app/shared/Strategies/CustomRouteReuse";
import {Role} from "src/app/shared/models/RolePerm.model";

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        canActivate: [IsNotAuthInterceptor],
    },
    {
        path: 'pages',
        component: PagesComponent,
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthInterceptor, VerificaPermissao],
        canActivateChild: [AuthInterceptor, VerificaPermissao],
    },
    {
        path: 'relatorios',
        loadChildren: () => import('./relatorios/relatorios.module').then(m => m.RelatoriosModule),
        canActivate: [AuthInterceptor, VerificaPermissao],
        canActivateChild: [AuthInterceptor, VerificaPermissao],
        data: {
            role: Role.VisualizaRelatorios,
        }
    },
    {
        path: 'central-vendas',
        loadChildren: () => import('./central-vendas/central-vendas.module').then(m => m.CentralVendasModule),
    },
    {
        path: 'default',
        component: NoAuthPagesComponent,
        loadChildren: () => import('./no-auth-pages/no-auth-pages.module').then(m => m.NoAuthPagesModule),
    },
    {path: '', redirectTo: 'pages', pathMatch: 'full'},
    {path: '**', redirectTo: 'pages'},
];

const config: ExtraOptions = {
    useHash: true
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
    providers: [
        // Isso aqui embaixo faz funcionar os @Input()´s com informações das rotas
        provideRouter(routes, withComponentInputBinding()),

        {provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy},
    ]
})
export class AppRoutingModule {
}
