import {NgModule} from '@angular/core';
import {ButtonsComponent} from "./buttons.component";
import {LoadingButtonModule} from "src/app/shared/directives/loadingButton/loadingButton.module";
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
    imports: [
        CommonModule,
        LoadingButtonModule,
        MatButtonModule,
    ],
    exports: [ButtonsComponent],
    declarations: [ButtonsComponent]
})
export class ButtonsModule {
}
