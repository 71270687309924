import {Router} from "@angular/router";
import {CommonModule} from '@angular/common';
import {Component, Inject} from "@angular/core";
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {MatIconModule} from '@angular/material/icon';
import {SnackMessageConfig} from "src/app/shared/utils/utils";
import {SafeHtmlPipe} from "src/app/shared/pipes/safeHTML.pipe";

@Component({
    selector: 'my-snackbar',
    templateUrl: './mySnackBar.component.html',
    styleUrls: ['./mySnackBar.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        SafeHtmlPipe,
    ]
})

export class MySnackBarComponent {

    constructor(
        public router: Router,
        public snackBarRef: MatSnackBarRef<MySnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackMessageConfig,
    ) {
    }

    async close() {
        this.snackBarRef.dismiss();
    }

}
