<div class="sidenav mat-elevation-z5 flex flex-col">

    <ng-container *ngFor="let menu of menus$ | async">
        <app-menu-item *ngIf="!menu.hide" [menu]="menu" [(statsMenu)]="statsMenu"></app-menu-item>
    </ng-container>

    <div class="spacer"></div>

    <div class="version-item primary-text flex justify-start items-center">
        <span [@slideMenu]="statsMenu"> {{ version }} ({{ buildversion }}) </span>
    </div>

    <div class="menu-item primary-text flex justify-start items-center" (click)="toggleMenu()">
        <mat-icon> {{ statsMenu === 'open' ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right' }} </mat-icon>
        <span [@slideMenu]="statsMenu"> Diminuir menu lateral </span>
    </div>

</div>
