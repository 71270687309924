import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_CONFIG} from 'src/app/app.setings';
import {TickDetailsModel, TickModel} from "src/app/shared/models/tick.model";
import {Observable, firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: "root"
})
export class TickService {

    constructor(private http: HttpClient) {
    }

    async getAll() {
        return await firstValueFrom(this.http.get<TickModel[]>(`${API_CONFIG.url}tick-config/`));
    }

    async getById(id: any) {
        return await firstValueFrom(this.http.get<TickDetailsModel>(`${API_CONFIG.url}tick-config/${id}`));
    }

    async salvar(data) {
        return await firstValueFrom(this.http.post(`${API_CONFIG.url}tick-config/`, data));
    }

    async delete(id) {
        return await firstValueFrom(this.http.delete(`${API_CONFIG.url}tick-config/${id}`));
    }

    localizarTerminal(terminal: string): Observable<SearchTerminal[]> {
        return this.http.get<SearchTerminal[]>(`${API_CONFIG.url}tick-config/terminal/${terminal}`);
    }

}

export interface SearchTerminal {
    tick_codigo: number,
    tick_nome: string,
    ativo: boolean,
}
