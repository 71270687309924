import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {API_CONFIG} from "src/app/app.setings";
import {RelatorioV2Model} from "src/app/shared/models/relatorios/relatorio-v2.model";
import {firstValueFrom} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RelatorioV2Service {

    constructor(
        private http: HttpClient,
    ) {}

    public getAll(opts: {apenasAtivos?: boolean, editaveis?: boolean, estabelecs?: boolean} = {}) {
        let params: Record<string, any> = {}
        if (opts.apenasAtivos) params['ativos'] = true;
        if (opts.editaveis) params['editaveis'] = true;
        if (opts.estabelecs) params['estabelecs'] = true;
        return this.http.get<RelatorioV2Model[]>(`${API_CONFIG.url}relatorio-v2/`, {params});
    }

    public getToken() {
        return this.http.get<{token: string;}>(`${API_CONFIG.url}relatorio-v2/auth`);
    }

    public getById(id: string) {
        return this.http.get<RelatorioV2Model>(`${API_CONFIG.url}relatorio-v2/${id}`);
    }

    async save(relatorio: RelatorioV2Model, id?: string) {
        if (id) {
            return await firstValueFrom(this.http.put<{id: string;}>(`${API_CONFIG.url}relatorio-v2/` + id, relatorio));
        } else {
            return await firstValueFrom(this.http.post<{id: string;}>(`${API_CONFIG.url}relatorio-v2/`, relatorio));
        }
    }

    async delete(id: string) {
        return await firstValueFrom(this.http.delete(`${API_CONFIG.url}relatorio-v2/${id}`));
    }

}
