import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";

import {ListaCadastrosComBuscaComponent} from "./lista-cadastros-com-busca.component";
import {SearchInputModule} from "../search-input/search-input.module";
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from "@angular/material/sort";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {IsUserLimberDirective} from "src/app/shared/directives/isUserLimber/is-user-limber.directive";

@NgModule({
    imports: [
        CommonModule,
        SearchInputModule,
        FormsModule,
        MatTableModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatPaginatorModule,
        MatSortModule,
        LazyLoadImageModule,
        IsUserLimberDirective
    ],
    exports: [ListaCadastrosComBuscaComponent],
    declarations: [ListaCadastrosComBuscaComponent],
})
export class ListaCadastrosComBuscaModule {
}
