import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpInterceptor, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageFiltroEstabelecimentosService} from "src/app/shared/services/localStorage/storage-filtro-estabelecimentos.service";

// Interceptor que detecta requisições vindas dos nossos services e adiciona filtro de estabelecimentos que deseja ver;
@Injectable({
    providedIn: 'root',
})
export class ListaEstabelecimentosInterceptor implements HttpInterceptor {

    constructor(
        private filtroEstabelecimentosService: StorageFiltroEstabelecimentosService,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const dupReq = req.clone({
            headers: req.headers.set('listaestabelecimentos', JSON.stringify(this.filtroEstabelecimentosService.get())),
        });
        return next.handle(dupReq);
    }
}
