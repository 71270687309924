import {UsuarioLocalVendaModel} from 'src/app/shared/models/Usuario-local-venda.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LimberValidators} from 'src/app/shared/utils/validators';
import {Abstract} from 'src/app/shared/models/abstract';
import {Subscription} from "rxjs";
import {PerfilVendaModel} from "src/app/shared/models/PerfilVendaModel";
import {TiposEstabelec} from "src/app/shared/models/estabelecimentos/estabelecimento";
import {CentralVendaModel} from "src/app/shared/models/CentralVendaModel";

export class ReduceEstabelecimentoModel extends Abstract {

    codigo: number = null;
    nome: string = null;
    tipo: TiposEstabelec = null;
    tipo_nome: string = null;
    cnpj: string = null;
    centralVendas: CentralVendaModel = new CentralVendaModel();

    getTipos: Record<TiposEstabelec | 'null', string> = {
        'AG': 'Agência',
        'BI': 'Bilheteria',
        'BO': 'Bilheteria On-line',
        'EC': 'Ecommerce',
        'IA': 'Integração via API',
        'TK': 'Tick ingressos',
        'CV': 'Central de Vendas',
        'null': 'Sem tipo',
    }
}

export class SimpleEstabelecimentoModel extends ReduceEstabelecimentoModel {
    caminho: string = null;
    host: string = '0'; //começa preenchido
    id_integracao: string = null;
    porta: number = null;
    senha: string = null;
    versao: number = null;
    qtd_perfis_ativos: number;
}

export class EstabelecimentoModel extends SimpleEstabelecimentoModel {

    capacidade_maxima: number = null;
    email: string = null;
    email_cco: string = null;
    envia_vouchers: boolean = null;
    coleta_utilizacao_vouchers: boolean = null;
    coleta_vendas_bilheteria: boolean = null;
    pedido_canc_portal: boolean = null;
    intervalo_tempo_pagamento: number = null;
    envia_reservas_tempo_real: boolean = true;
    soft_descriptor: string = null;
    pais: number = null;
    cep: string = null;
    estado: string = null;
    cidade: string = null;
    bairro: string = null;
    endereco: string = null;
    numero: string = null;
    complemento: string = null;
    path_logo: string = null;
    url_logo: string = null;
    imagem_logo: string = null;
    permite_utilizacao_card: boolean = null;
    integracao_bil_padrao: boolean = null;
    integra_agencias_bilheteria_padrao: boolean = null;
    desativa_antifraude: boolean = null;
    empresa: number = null;
    estabelecimento: number = null;
    ag_exterior: boolean = false;
    habilitar_link_pagamento: boolean = false;
    qtd_usuarios_link_pagamento: number = null;
    habilita_modulo_pricing: boolean = false;

    latitude: number = null;
    longitude: number = null;

    perfis_venda: PerfilVendaModel[] = [];
    usuarios: UsuarioLocalVendaModel[] = [];
    ativo: boolean = true;
    slug: string = null;


    getForm(): {form: FormGroup<FormEstab>, subForm: Subscription} {
        let form = new FormGroup<FormEstab>({
            gerais: new FormGroup({
                nome: new FormControl(this.nome, Validators.required),
                tipo: new FormControl(this.tipo, Validators.required),
                cnpj: new FormControl(this.cnpj, [LimberValidators.validateCpfOrCnpj]),
                id_integracao: new FormControl(this.id_integracao, Validators.required),
                capacidade_maxima: new FormControl(this.capacidade_maxima),
                email: new FormControl(this.email, [Validators.email]),
                email_cco: new FormControl(this.email_cco, [Validators.email]),
                ag_exterior: new FormControl(this.ag_exterior),
                soft_descriptor: new FormControl(this.soft_descriptor),
                path_logo: new FormControl(this.path_logo),
                imagem_logo: new FormControl(this.imagem_logo),
                pedido_canc_portal: new FormControl(this.pedido_canc_portal),
                intervalo_tempo_pagamento: new FormControl(this.intervalo_tempo_pagamento),
                permite_utilizacao_card: new FormControl(this.permite_utilizacao_card),
                desativa_antifraude: new FormControl(this.desativa_antifraude),
                ativo: new FormControl(this.ativo),
                slug: new FormControl(this.slug, LimberValidators.slug),
                pais: new FormControl(this.pais),
                cep: new FormControl(this.cep),
                estado: new FormControl(this.estado),
                cidade: new FormControl(this.cidade),
                bairro: new FormControl(this.bairro),
                endereco: new FormControl(this.endereco),
                numero: new FormControl(this.numero),
                complemento: new FormControl(this.complemento),
                latitude: new FormControl<number>(this.latitude),
                longitude: new FormControl<number>(this.longitude),
                habilitar_link_pagamento: new FormControl<boolean>(this.habilitar_link_pagamento),
                qtd_usuarios_link_pagamento: new FormControl<number>(this.qtd_usuarios_link_pagamento, [Validators.min(0)]),
                habilita_modulo_pricing: new FormControl<boolean>({value: this.habilita_modulo_pricing, disabled: !['BI', 'BO'].includes(this.tipo)}),
            }),
            bilheteria: new FormGroup({
                versao: new FormControl(this.versao),
                host: new FormControl(this.host),
                porta: new FormControl(this.porta),
                caminho: new FormControl(this.caminho),
                senha: new FormControl(this.senha),
                envia_vouchers: new FormControl(this.envia_vouchers),
                coleta_utilizacao_vouchers: new FormControl(this.coleta_utilizacao_vouchers),
                coleta_vendas_bilheteria: new FormControl(this.coleta_vendas_bilheteria),
                envia_reservas_tempo_real: new FormControl(this.envia_reservas_tempo_real),
                integracao_bil_padrao: new FormControl(this.integracao_bil_padrao),
                integra_agencias_bilheteria_padrao: new FormControl(this.integra_agencias_bilheteria_padrao),
                empresa: new FormControl(this.empresa),
                estabelecimento: new FormControl(this.estabelecimento),
            }),
            centralVendas: new FormGroup<CentralVendasForm>({
                ec_origem: new FormControl(this.centralVendas?.ec_origem, Validators.required),
            }),

        });

        let subForm: Subscription = form.valueChanges.subscribe((value) => {
            for (let cntl in value) {
                for (let campo in form.get(cntl).value) {
                    this[campo] = form.get(cntl).value[campo];
                }
            }
            this.centralVendas.ec_origem = form.get('centralVendas').value.ec_origem;
        });
        return {form, subForm};
    };

    /**
     * @param hideFunc - função utilitária para esconder uma opção se for preciso com as regras configuráveis
     */
    tiposEstab: { value: TiposEstabelec, viewValue: string, hideFunc?: () => boolean }[] = [
        {value: null, viewValue: ''},
        {value: 'AG', viewValue: 'Agência'},
        {value: 'BI', viewValue: 'Bilheteria'},
        {value: 'BO', viewValue: 'Bilheteria On-line'},
        {value: 'EC', viewValue: 'Ecommerce'},
        {value: 'IA', viewValue: 'Integração via API'},
        {value: 'TK', viewValue: 'Tick ingressos'},
        {value: 'CV', viewValue: 'Central de Vendas'},
    ];

    /**
     * Retorno a label do tipo de estabelecimento deste
     */
    getTipoEstab(): string {
        return this.tiposEstab.find(tipo => tipo.value === this.tipo).viewValue;
    }

    public displayedColumnsPerfis = [
        'codigo',
        'nome',
        'estabelecCadastro',
        'modoPag',
        'options',
    ];

}

export interface FormEstab {
    gerais: FormGroup<GeraisForm>;
    bilheteria: FormGroup<BilheteriaForm>;
    centralVendas: FormGroup<CentralVendasForm>;
}

export interface GeraisForm {
    tipo: FormControl<TiposEstabelec>,
    nome: FormControl<string>,
    ag_exterior: FormControl<boolean>,
    cnpj: FormControl<string>,
    pedido_canc_portal: FormControl<boolean>,
    intervalo_tempo_pagamento: FormControl<number>,
    capacidade_maxima: FormControl<number>,
    email_cco: FormControl<string>,
    email: FormControl<string>,
    soft_descriptor: FormControl<string>,
    path_logo: FormControl<string>,
    imagem_logo: FormControl<string>,
    permite_utilizacao_card: FormControl<boolean>,
    desativa_antifraude: FormControl<boolean>,
    id_integracao: FormControl<string>,
    ativo: FormControl<boolean>,
    slug: FormControl<string>,
    pais: FormControl<number>,
    cep: FormControl<string>,
    estado: FormControl<string>,
    cidade: FormControl<string>,
    bairro: FormControl<string>,
    endereco: FormControl<string>,
    numero: FormControl<string>,
    complemento: FormControl<string>,
    latitude: FormControl<number>,
    longitude: FormControl<number>,
    habilitar_link_pagamento: FormControl<boolean>,
    habilita_modulo_pricing: FormControl<boolean>,
    qtd_usuarios_link_pagamento: FormControl<number>,
}

export interface BilheteriaForm {
    versao: FormControl<number>,
    envia_vouchers: FormControl<boolean>,
    coleta_utilizacao_vouchers: FormControl<boolean>,
    coleta_vendas_bilheteria: FormControl<boolean>,
    envia_reservas_tempo_real: FormControl<boolean>,
    integracao_bil_padrao: FormControl<boolean>,
    integra_agencias_bilheteria_padrao: FormControl<boolean>,
    host: FormControl<string>,
    porta: FormControl<number>,
    caminho: FormControl<string>,
    senha: FormControl<string>,
    empresa: FormControl<number>,
    estabelecimento: FormControl<number>,
}

export interface CentralVendasForm {
    ec_origem: FormControl<number>,
}
