import {CardCryptoStorage} from "src/app/shared/services/localStorage/CardCryptoStorage";
import {Permission} from "src/app/shared/models/auth/Permission";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class StoragePermissionsUserService extends CardCryptoStorage<Permission[]> {
    cryptoPass: string = 'p3rm1co3s';
    key: string = 'DJa4jm29d73msg82mdhq2Dne74D';
    isJson: boolean = true;
}
