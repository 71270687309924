import {Component, OnInit, ChangeDetectionStrategy, effect} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {LocalizarTerminalComponent} from 'src/app/shared/components/sidenav/localizar-terminal/localizar-terminal.component';
import {MatDialog} from '@angular/material/dialog';
import packageJson from 'package.json';
import {Subject} from 'rxjs';
import {AuthenticatedUserService} from 'src/app/shared/services/store/authenticatedUser.service';
import {RelatorioV2Model, TipoPermissaoRelatorio} from 'src/app/shared/models/relatorios/relatorio-v2.model';
import {RelatorioV2Service} from 'src/app/shared/services/relatorio-v2.service';

@Component({
    selector: 'card-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    animations: [
        trigger('slideMenu', [
            state('open', style({
                width: '*',
                opacity: 1,
                'margin-left': '10px',
            })),
            state('close', style({
                width: '0',
                opacity: 0,
                'margin-left': '0px',
            })),
            transition('close => open', animate('150ms ease-in')),
            transition('open => close', animate('150ms ease-out')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit {

    public statsMenu: 'open' | 'close' = localStorage.getItem('menulateral') as any || 'open';

    public menus$: Subject<itemMenu[]> = new Subject<itemMenu[]>();

    constructor(
        private dialog: MatDialog,
        private relatorioService: RelatorioV2Service,
        private authenticatedUserService: AuthenticatedUserService,
    ) {
        effect(() => {
            this.carregaPermissaoMenu(this.authenticatedUserService.isUserLimber());
        });
    }

    async ngOnInit() {
        this.menus$.next(this.menus);
        this.adicionaMenusDeRelatorios();
    }

    private adicionaMenusDeRelatorios() {
        this.relatorioService.getAll()
            .subscribe({
                next: (relatorios: RelatorioV2Model[]) => {
                    this.relatoriosMenuItem.childrens.push(
                        ...relatorios.filter(rel => rel.tipo_permissao === TipoPermissaoRelatorio.PADRAO_CARD).map((rel) => {
                            return {
                                label: rel.nome,
                                link: `/relatorios`,
                                query: {
                                    relatorio: rel.codigo,
                                },
                            };
                        }),
                    );
                    this.menus$.next(this.menus);
                },
                error: (err) => {
                    console.error(err);
                },
            });
    }

    toggleMenu(): void {
        this.statsMenu = this.statsMenu === 'open' ? 'close' : 'open';
        this.setStatusMenuToStorage();
    }

    private setStatusMenuToStorage() {
        localStorage.setItem('menulateral', this.statsMenu);
    }

    carregaPermissaoMenu(userLimber: boolean) {
        if (this.authenticatedUserService.isAuthenticated()) {
            let permissoes = this.authenticatedUserService.permissions();

            if (permissoes) {
                let visualizaRel = permissoes.some(perm => perm.visualiza_relatorios_venda >= 1);
                this.gerenciamentoMenuItem.hide = !visualizaRel;
                this.dashboardMenuItem.hide = !visualizaRel;
                let alteraCadastro = !permissoes.some(perm => perm.altera_cadastro === 'S');
                this.cadastroMenuItem.hide = alteraCadastro;
                this.configPrecoMenuItem.hide = alteraCadastro;
                this.sacMenuItem.hide = !permissoes.some(perm => perm.sac === 'S');
                let administrador = !permissoes.some(perm => perm.administrador === 'S');
                this.configMenuItem.hide = administrador;
                this.monitorMenuItem.hide = administrador;
                this.ecommerceMenuItem.hide = !permissoes.some(perm => perm.administrador === 'S' && perm.tipo === 'EC');
                this.agenciasMenuItem.hide = !permissoes.some(perm => perm.administrador === 'S' && perm.tipo === 'EC');
                this.tickMenuItem.hide = !permissoes.some(perm => perm.administrador === 'S' && perm.tipo === 'TK');
                this.gerenciamentoMenuItem.childrens.find(child => child.label === 'Verificador Transações').hide = !userLimber;
                this.menus$.next(this.menus);
            }
        }
    }

    public version: string = packageJson.version;
    public buildversion: string = String(packageJson.buildversion);

    public dashboardMenuItem: itemMenu = {
        label: 'Dashboard',
        icon: 'bar_chart',
        link: '/pages/dashboard',
    };

    public cadastroMenuItem: itemMenu = {
        icon: 'local_play',
        label: 'Cadastros',
        childrens: [
            {label: 'Bilhetes', link: '/pages/cadastro/bilhete'},
            {label: 'Grupos de Bilhetes', link: '/pages/cadastro/grupo-bilhete'},
            {label: 'Grupos de Categorias', link: '/pages/cadastro/grupo-categoria'},
            {label: 'Categorias', link: '/pages/cadastro/categorias'},
            {label: 'Receitas', link: '/pages/cadastro/receitas'},
            {label: 'Modos de pagamento', link: '/pages/cadastro/modosPagamentos'},
            {label: 'Termos de Aceite', link: '/pages/cadastro/termos-aceite'},
            {label: 'Textos de E-mail', link: '/pages/cadastro/textos-email'},
            {label: 'SAC\'s', link: '/pages/cadastro/sacs'},
            {label: 'Credenciados', link: '/pages/cadastro/credenciados'},
            {label: 'FAQ\'s', link: '/pages/cadastro/faqs'},
            {label: 'Tags', link: '/pages/cadastro/tags'},
            {label: 'Blocos de texto', link: '/pages/cadastro/blocos-texto'},
            {label: 'Envios de E-mails', link: '/pages/cadastro/envios-de-emails'},
            {label: 'Relatórios V2', link: '/pages/cadastro/relatorios-v2', hide: !this.authenticatedUserService.isUserLimber()},
            {label: 'Agendamentos WhatsApp', link: '/pages/cadastro/agendamentos'},
            {label: 'Metas', link: '/pages/cadastro/metas'},
        ],
    };

    public bilheteriaMenuItem: itemMenu = {
        label: 'Bilheteria',
        icon: 'storefront',
        link: '/pages/bilheteria/',
    };

    public configPrecoMenuItem: itemMenu = {
        label: 'Preços e Disponibilidade',
        icon: 'paid',
        childrens: [
            {label: 'Configuração de preços e disponibilidades', link: '/pages/calendarioPrecoDisp/config'},
            {label: 'Gerenciamento de Lotes', link: '/pages/calendarioPrecoDisp/lotes', atrakto: true},
            {label: 'Ocupação da disponibilidade online', link: '/pages/calendarioPrecoDisp/disp-online'},
        ],
    };

    public gerenciamentoMenuItem: itemMenu = {
        label: 'Gerenciamento',
        icon: 'format_list_bulleted',
        childrens: [
            {label: 'Gerenciamento de Vendas', link: '/pages/gerenciamento/vendas'},
            {label: 'Extrato Eletrônico de Transações', link: '/pages/gerenciamento/split'},
            {label: 'Compradores', link: '/pages/gerenciamento/compradores'},
            {label: 'Verificador Transações', link: '/pages/gerenciamento/transacoes', hide: !this.authenticatedUserService.isUserLimber()},
        ],
    };

    public relatoriosMenuItem: itemMenu = {
        label: 'Relatórios',
        icon: 'query_stats',
        link: '/relatorios',
        childrens: [],
    };

    public sacMenuItem: itemMenu = {
        label: 'SAC',
        icon: 'email',
        link: '/pages/sac/chamados',
    };

    public agenciasMenuItem: itemMenu = {
        label: 'Agências',
        icon: 'assignment_ind',
        childrens: [
            {label: 'Requisições de acordo', link: '/pages/agencias/reqAcordos'},
        ],
    };

    public configMenuItem: itemMenu = {
        label: 'Configurações',
        icon: 'settings',
        childrens: [
            {label: 'Estabelecimentos', link: '/pages/config/estabelecimentos'},
            {label: 'Perfis de venda', link: '/pages/config/perfis'},
            {label: 'Usuários', link: '/pages/config/usuarios'},
            {label: 'Webhook', link: '/pages/config/webhooks'},
        ],
    };

    public monitorMenuItem: itemMenu = {
        label: 'Monitoramento',
        icon: 'visibility',
        childrens: [
            {label: 'Notificações', link: '/pages/config/notificacoes'},
            {label: 'Logs Nuvem', link: '/pages/config/logs-nuvem'},

        ],
    };

    public ecommerceMenuItem: itemMenu = {
        label: 'E-commerce',
        icon: 'shopping_cart',
        childrens: [
            {label: 'Cadastro de E-commerce´s', link: '/pages/ecommerce/ec-configs'},
            {label: 'Clientes', link: '/pages/ecommerce/ec-clientes'},
            {label: 'Cupons de Desconto', link: '/pages/ecommerce/cupons'},
        ],
    };

    private localizarTerminal = () => {
        this.dialog.open(LocalizarTerminalComponent, {
            width: '600px',
            maxWidth: '90vw',
            maxHeight: '90vh',
        });
    };

    public tickMenuItem: itemMenu = {
        label: 'Tick Ingressos',
        icon: 'app_settings_alt',
        childrens: [
            {label: 'Cadastro de Tick Ingressos', link: '/pages/tick/tick-ingressos'},
            {label: 'Localizar Terminal', customEvent: this.localizarTerminal},
        ],
    };

    public toolsMenuItem: itemMenu = {
        label: 'Ferramentas',
        icon: 'handyman',
        hide: !this.authenticatedUserService.isUserLimber(),
        childrens: [
            {label: 'Agrupador de Agências', link: '/pages/tools/agrupador-agencias'},
        ],
    };

    public noAuthMenuItem: itemMenu = {
        label: 'Páginas sem autenticação',
        icon: 'no_accounts',
        hide: !this.authenticatedUserService.isUserLimber(),
        childrens: [
            {label: 'Consulta por código promocional', link: '/default/consulta-promocional'},
            {label: 'Documentação - Utilização de Ingressos', customEvent: () => window.open('/assets/open-api/utilizacao-de-ingressos.html')},
            {label: 'Documentação - Integração de Vendas', customEvent: () => window.open('/assets/open-api/integracao-de-vendas.html')},
        ],
    };

    public centralVendasMenuItem: itemMenu = {
        label: 'Central de Vendas',
        icon: 'headset_mic',
        link: '/central-vendas/estabelecimentos',
    };


    public menus: itemMenu[] = [
        this.dashboardMenuItem,
        this.gerenciamentoMenuItem,
        this.relatoriosMenuItem,
        this.cadastroMenuItem,
        this.configPrecoMenuItem,
        this.sacMenuItem,
        this.agenciasMenuItem,
        this.bilheteriaMenuItem,
        this.configMenuItem,
        this.monitorMenuItem,
        this.ecommerceMenuItem,
        this.tickMenuItem,
        this.toolsMenuItem,
        this.noAuthMenuItem,
        this.centralVendasMenuItem,
    ];
}

export interface itemMenu extends Item {
    /** ícone mostrado em tela - Material Design */
    icon?: string,

    /** Itens do menu detalhe */
    childrens?: Item[],
}

export interface Item {
    /** String que é mostrada em tela */
    label: string,

    /** Link para o próprio projeto */
    link?: string,

    /** Parámetros de query para ser adicionados à URL */
    query?: Record<string, string>,

    /** Determina de esconde ou não a opção */
    hide?: boolean,

    /** Função que ativa quando clicada */
    customEvent?: Function;

    atrakto?: boolean
}
