import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirmationDialog.component.html',
})

export class ConfirmationDialog {

    public confirmMessage: string;

    public ok: boolean = false;
    public alert: boolean = true;
    public showCancelar: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialog>
    ) {
    }
}
