import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticatedUserService} from "src/app/shared/services/store/authenticatedUser.service";
import {AuthService} from "src/app/shared/services/auth.service";

// Interceptor que libera acesso as rotas e as rotas filhas se o usuario estiver autenticado
@Injectable({
    providedIn: "root"
})
export class AuthInterceptor  {

    constructor(private authenticatedUserService: AuthenticatedUserService, private auth: AuthService) {
    }

    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
        return await this.validatorAuthenticated();
    }

    async canActivateChild(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
        return await this.validatorAuthenticated();
    }

    async validatorAuthenticated(): Promise<boolean> {
        if (this.authenticatedUserService.isAuthenticated()) {
            document.getElementById('limber-splash-screen').style.display = 'none';
            return true;
        }
        this.authenticatedUserService.clearStorage();
        return false;
    }
}
