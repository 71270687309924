import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Validators, FormControl} from "@angular/forms";
import {catchError, finalize, Observable, of, throwError, map} from 'rxjs';
import {TickService, SearchTerminal} from "src/app/shared/services/tick.service";
import {Utils} from "src/app/shared/utils/utils";

@Component({
    selector: 'app-localizar-terminal',
    templateUrl: './localizar-terminal.component.html',
    styleUrls: ['./localizar-terminal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalizarTerminalComponent {

    public loading: boolean = false;
    public terminal: FormControl<string> = new FormControl(null, Validators.required);
    public naoEncontrado: boolean = false;

    public ticks$: Observable<SearchTerminal[]>;

    constructor(
        private tickService: TickService,
        private utils: Utils,
    ) {
    }

    buscar() {
        this.loading = true;
        this.ticks$ = this.tickService.localizarTerminal(this.terminal.value)
            .pipe(
                map(ticks => {
                    return ticks.sort(tick => tick.ativo ? -1 : 1)
                }),
                catchError((err) => {
                    console.error(err);
                    this.naoEncontrado = true;
                    return of([]);
                }),
                finalize(() => this.loading = false),
            );
    }

    async goToTick(tick: SearchTerminal) {
        await this.utils.openNewPage(`/#/pages/tick/tick-ingresso`, {id: tick.tick_codigo});
    }

}
