import { Injectable } from '@angular/core';
import {CardCryptoStorage} from "src/app/shared/services/localStorage/CardCryptoStorage";
import {LoginResponse} from "src/app/shared/models/auth/LoginResponse";

@Injectable({
  providedIn: 'root'
})
export class StorageUserService extends CardCryptoStorage<Partial<LoginResponse>> {
    cryptoPass: string = 'l0ggedUs3r';
    key: string = 'poi3noosfoin34oi9vh98d3usefneiosf';
    isJson: boolean = true;
}
