import {Injectable} from '@angular/core';
    import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class SerializeParams {

    // Metodo que faz a conversão de um objeto para parametro de URL
    // Esse metodo facilita o uso de uma consulta HTTP do tipo GET com muitos parametro, já que assim não precisamos escrever parametro por parametro
    getParams(data: object): string { // Esse metodo recebe um objeto e retorna uma string com as seguintes caracteristicas: ?${key}=&{valor}
        let params = '?';
        let cont = 0;
        if (typeof data === 'object') {
            for (const key in data) { // Intera as keys do objeto
                if (data[key]) {
                    if (data.hasOwnProperty(key)) {
                        let isDate = false;
                        try {
                            if ((key.toUpperCase()).includes('DATA')) {
                                isDate = true;
                            }
                        } catch {
                            isDate = false;
                        } finally {
                            if (isDate) {
                                data[key] = data[key] ? moment(data[key]).format('YYYY-MM-DD') : '';
                            }

                            // Cocatena o que já tem na variavel params com a chave do objeto + '=' + o valor do objeto
                            if (cont === 0) { // No priemiro parametro não se deve adicionar o &
                                params += (key + '=' + data[key]);
                            } else {
                                params += ('&' + key + '=' + data[key]);
                            }
                        }
                    }
                    cont++;
                }
            }
        }
        return params;
    }
}
