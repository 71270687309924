import {Injectable} from '@angular/core';
import {Permission} from "src/app/shared/models/auth/Permission";
import {firstValueFrom} from "rxjs";
import {API_CONFIG} from "src/app/app.setings";
import {HttpClient} from "@angular/common/http";
import {LoginResponse} from "src/app/shared/models/auth/LoginResponse";

@Injectable({
    providedIn: "root"
})
export class AuthService {

    constructor(
        private http: HttpClient,
    ) {
    }

    async login(data: {usuario: string, senha: string}): Promise<LoginResponse> {
        return await firstValueFrom(this.http.post<LoginResponse>(`${API_CONFIG.url}auth`, data));
    }

    async permissao(idUser: number) {
        return await firstValueFrom(this.http.get<Permission[]>(`${API_CONFIG.url}auth/permissao/${idUser}`));
    }
}
