import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_CONFIG} from 'src/app/app.setings';
import {NotificacoesModel, LogSyncModel} from "src/app/shared/models/Notificacoes.model";

@Injectable({
    providedIn: "root"
})
export class LogsService {

    constructor(private http: HttpClient) {
    }

    async getAll() {
        return await this.http.get<{ notificacoes: NotificacoesModel[], logsSync: LogSyncModel[] }>(API_CONFIG.url + 'logs/').toPromise();
    }

    async getNotificacoes(): Promise<any[]> {
        return await this.http.get<any[]>(API_CONFIG.url + 'logs/notificacoes').toPromise();
    }

    async getLogsSync(): Promise<any[]> {
        return await this.http.get<any[]>(API_CONFIG.url + 'logs/sync').toPromise();
    }

}
