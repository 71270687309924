import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpEventType, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Utils} from "src/app/shared/utils/utils";
import {AuthenticatedUserService} from "src/app/shared/services/store/authenticatedUser.service";
import {StorageTokenService} from "src/app/shared/services/localStorage/storage-token.service";

// Interceptor que detecda requisições vindas dos nossos services e adiciona o token do usuario nos headers da requisição
@Injectable({
    providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private storageTokenService: StorageTokenService,
        private utils: Utils,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const headers = request.headers.set('Authorization', ((this.storageTokenService.get()) ? 'Bearer ' + this.storageTokenService.get() : ''));
        const requestClone = request.clone({
            headers,
        });
        return next.handle(requestClone)
            .pipe(
                map(event => {
                    let errorId = '#### Chunked Error: ';
                    if (event.type === HttpEventType.DownloadProgress) {
                        if (event['partialText'].includes(errorId)) {
                            this.utils.openSnackBar(event['partialText'].split(errorId)[1], 'error');
                            throw new Error(event['partialText'].split(errorId)[1]);
                        }
                    }
                    return event;
                }),
                catchError((err: HttpErrorResponse) => {
                    let {error, ...params} = err;

                    if (typeof error === 'string') {
                        try {
                            error = JSON.parse(err.error);
                        } catch (e) {
                            if (err.error.includes('nginx')) {
                                error = {message: err.error.match(/<title>([^<]*)<\/title>/)[1]};
                            }
                        }

                        err = new HttpErrorResponse({ error, ...params, });
                    }
                    this.utils.openSnackBar(err.error.message, 'error');
                    if (err.status === 401 || err.statusText === 'Unauthorized' || err.statusText === 'Forbidden' || err.error === 'Unauthorized') {
                        console.log('Usuario não logado');
                        this.authenticatedUserService.clearStorage();
                    }
                    return throwError(() => err);
                })
            );
    }
}
