import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_CONFIG} from 'src/app/app.setings';
import {EstabelecimentoModel, ReduceEstabelecimentoModel} from "src/app/shared/models/estabelecimentos/Estabelecimento.model";
import {SerializeParams} from "src/app/shared/utils/serializeParams";
import {PerfilVendaModel} from "src/app/shared/models/PerfilVendaModel";
import {firstValueFrom, Observable} from 'rxjs';
import {TiposEstabelec, Estabelecimento} from "src/app/shared/models/estabelecimentos/estabelecimento";

@Injectable({
    providedIn: "root"
})
export class EstabelecimentoService {

    constructor(
        private http: HttpClient,
        private serialize: SerializeParams
    ) {
    }

    getAllSimple$(tipos?: string[]) {
        let params: {tipos?: string[]} = {};
        if (tipos?.length) {
            params.tipos = tipos;
        }
        return this.http.get<ReduceEstabelecimentoModel[]>(`${API_CONFIG.url}estabelec/simples`, {params});
    }

    // ### Parâmetros passados no "tipos" deve ser passados separados por vírgula( , );
    // ### TIPOS = 'EC' | 'BI' | 'BO' | 'TK' | 'AG' | 'IA'
    async getAll(tipos?: TiposEstabelec[], detalhado?: boolean, apenasAtivos?: boolean, moduloPricing?: boolean): Promise<EstabelecimentoModel[]> {
        let params = {tipos: tipos?.toString() || null, detalhado, apenasAtivos, moduloPricing};
        return await firstValueFrom(this.http.get<EstabelecimentoModel[]>(`${API_CONFIG.url}estabelec${this.serialize.getParams(params)}`));
    }

    getAll$(tipos?: string | TiposEstabelec[], detalhado?: boolean, apenasAtivos?: boolean, moduloPricing?: boolean): Observable<EstabelecimentoModel[]> {
        let params = {tipos: tipos?.toString() || null, detalhado, apenasAtivos, moduloPricing};
        return this.http.get<EstabelecimentoModel[]>(`${API_CONFIG.url}estabelec${this.serialize.getParams(params)}`);
    }

    getLocaisVendaDosPerfis() {
        return this.http.get<any[]>(`${API_CONFIG.url}estabelec/locaisVendaPerfis`);
    }

    async getEstabelecByParams(params: { cpnj?: string, exceptCode?: number, tipo?: TiposEstabelec}) {
        if (params.cpnj) {
            params.cpnj = encodeURIComponent(params.cpnj);
        }
        return await firstValueFrom(this.http.get<{ exists: boolean, original: number, addPerm: boolean }>(`${API_CONFIG.url}estabelec/byParams${this.serialize.getParams(params)}`));
    }

    async getById(id: number): Promise<Estabelecimento> {
        return firstValueFrom(this.http.get<any>(`${API_CONFIG.url}estabelec/${id}`));
    }

    getEstabelecById$(id: number) {
        return this.http.get<Estabelecimento>(`${API_CONFIG.url}estabelec/${id}`);
    }

    async getPerfisVenda(id: number) {
        return await firstValueFrom(this.http.get<PerfilVendaModel[]>(`${API_CONFIG.url}estabelec/perfisVenda/${id}`));
    }

    async getUserById(id: number): Promise<UserByEstabelec[]> {
        return await firstValueFrom(this.http.get<UserByEstabelec[]>(`${API_CONFIG.url}estabelec/usuarios/${id}`));
    }

    async mesclarEstabelecimentos(data: BodyMesclar) {
        return await firstValueFrom(this.http.patch(`${API_CONFIG.url}estabelec/mesclar`, data));
    }

    async salvar(data: EstabelecimentoModel) {
        return await firstValueFrom(this.http.post(`${API_CONFIG.url}estabelec/`, data));
    }

    async delete(id: number) {
        return await firstValueFrom(this.http.delete(`${API_CONFIG.url}estabelec/${id}`));
    }

    async testConn(estab: EstabelecimentoModel) {
        return await firstValueFrom(this.http.post(`${API_CONFIG.url}estabelec/test`, estab));
    }

    async versaoBilheteria(infoBilheteria: EstabelecimentoModel): Promise<number> {
        return firstValueFrom(this.http.post<number>(`${API_CONFIG.url}estabelec/versaoBilheteria`, infoBilheteria));
    }

    public async getCep(cep: string): Promise<any> {
        return firstValueFrom(this.http.get<any>(API_CONFIG.url + `cross/cep/${cep}`));
    }
}

export interface UserByEstabelec {
    nivel: number;
    codigo: number,
    nome: string,
    login: string,
    email: string;
    novoUsuario: boolean,
    qtd_vendas: number,
    mergedUser: UserByEstabelec,
}

export interface BodyMesclar {
    users: UserByEstabelec[],
    new: EstabelecimentoModel,
    old: EstabelecimentoModel,
}
