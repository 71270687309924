import {NgModule} from '@angular/core';
import {AddPermissaoAcessoDialog} from "./addPermissaoAcessoDialog.component";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {SearchInputModule} from "../search-input/search-input.module";
import {TableWithSelectionModule} from "src/app/shared/components/table-with-selection/table-with-selection.module";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        SearchInputModule,
        TableWithSelectionModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
    ],
    exports: [AddPermissaoAcessoDialog],
    declarations: [AddPermissaoAcessoDialog]
})
export class AddPermissaoAcessoDialogModule {
}
