import {Subscription} from "rxjs";

export abstract class Abstract {

    constructor(props?: object) {
        if (props) this.assign(props);
    }

    /**
     * Passa os parâmetros enviados no *props* para a classe escolhida
     * @param props
     */
    assign(props) {
        for (const key of Object.keys(props)) {
            this[key] = props[key];
        }
        return this;
    }

    subscribeFormChangeToUpdateClass(form): Subscription {
        return form.valueChanges.subscribe((value) => {
            for (let cntl in value) {
                this[cntl] = form.controls[cntl].value;
            }
        });
    }

}
