import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";

import {ConfirmationDialog} from './confirmationDialog.component';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
    imports: [
        MatIconModule,
        CommonModule,
        MatButtonModule
    ],
    exports: [ConfirmationDialog],
    declarations: [ConfirmationDialog],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConfirmationDialogModule {
}
