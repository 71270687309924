<div class="fullpage">

    <card-toolbar></card-toolbar>

    <div class="padding-toolbar flex flex-row">

        <card-sidenav></card-sidenav>

        <div class="pages bg-gradient flex-1 max-sm:ml-[50px]"
             [ngClass]="hasPadding ? 'px-2.5 py-4 sm:p-5' : 'p-0'">

            <router-outlet></router-outlet>

        </div>

    </div>

</div>
