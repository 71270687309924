import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticatedUserService} from "src/app/shared/services/store/authenticatedUser.service";

@Injectable({
    providedIn: 'root',
})
export class IsNotAuthInterceptor {

    constructor(private authenticatedUserService: AuthenticatedUserService) {
    }

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        return this.validatorAuthenticated();
    }

    canActivateChild(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        return this.validatorAuthenticated();
    }

    validatorAuthenticated(): boolean {
        return !this.authenticatedUserService.isAuthenticated();
    }
}
