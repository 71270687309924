import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CancelamentosItensComponent} from "src/app/shared/components/cancelamentos-itens/cancelamentos-itens.component";
import {LoadingButtonModule} from "src/app/shared/directives/loadingButton/loadingButton.module";
import { ComprovanteComponent } from './comprovante/comprovante.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatMenuModule} from "@angular/material/menu";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        LoadingButtonModule,
        MatProgressBarModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        ReactiveFormsModule,
    ],
    declarations: [CancelamentosItensComponent, ComprovanteComponent],
    exports: [
        CancelamentosItensComponent
    ]
})
export class CancelamentosItensModule {
}
