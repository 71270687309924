import {Component} from '@angular/core';
import {Router, NavigationEnd} from "@angular/router";

const NO_PADDING_ROUTES = [
    '/dashboard',
    '/relatorio/',
]
@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
})
export class PagesComponent {

    hasPadding: boolean = true;

    constructor(
        private router: Router,
    ) {
        router.events
            .forEach((event) => {
                if (event instanceof NavigationEnd) {
                    this.hasPadding = NO_PADDING_ROUTES.every(pattern => !this.router.url.includes(pattern));
                }
            })
            .then();
    }

}
