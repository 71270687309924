import {LOCALE_ID, NgModule, ErrorHandler} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_BASE_HREF, registerLocaleData} from "@angular/common";
import {RouterModule} from "@angular/router";
import {AppRoutingModule} from "src/app/app-routing.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {TokenInterceptor} from "src/app/shared/interceptors/token.interceptor";
import {ListaEstabelecimentosInterceptor} from "src/app/shared/interceptors/listaEstabelecimentos.interceptor";
import {ComponentsModule} from "src/app/shared/components/components.module";
import localePt from '@angular/common/locales/pt';
import {DragToSelectModule} from "ngx-drag-to-select";
import {ChunckedDialogInterceptor} from "src/app/shared/interceptors/chuncked-dialog.interceptor";
import {NewRelicErrorHandler} from "src/app/shared/Strategies/NewRelicErrorHandler";
import {AppOriginHeaderInterceptor} from "src/app/shared/interceptors/app-origin-header.interceptor";
import {MatNativeDateModule} from '@angular/material/core';
import {DestroyedDirective} from "src/app/shared/directives/destroyed/destroyed.directive";
import {LoadingPageComponent} from "src/app/shared/components/loading-page/loading-page.component";

registerLocaleData(localePt, 'pt');

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        DestroyedDirective,
        HttpClientModule,
        RouterModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        ComponentsModule,
        MatNativeDateModule,
        DragToSelectModule.forRoot({
            selectedClass: 'selected-item-calendar',
            shortcuts: {
                removeFromSelection: 'shift',
                addToSelection: 'meta',
            }
        }),
        LoadingPageComponent,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: NewRelicErrorHandler,
        },
        {
            provide: APP_BASE_HREF,
            useValue: '/'
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ListaEstabelecimentosInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ChunckedDialogInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppOriginHeaderInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
