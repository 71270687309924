import {Injectable} from '@angular/core';

const STORAGE_TOKEN_KEY = 'token';

@Injectable({
    providedIn: 'root'
})
export class StorageTokenService {

    public get(): string {
        return window.localStorage.getItem(STORAGE_TOKEN_KEY) || undefined;
    }

    public set(token: string) {
        window.localStorage.setItem(STORAGE_TOKEN_KEY, token);
    }

}
