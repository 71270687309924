import {NgModule} from '@angular/core';
import {SearchInputComponent} from "./search-input.component";
import {AutoFocusInputDirective} from "src/app/shared/directives/autoFocus/autoFocusInput.directive";
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        AutoFocusInputDirective
    ],
    exports: [
        SearchInputComponent
    ],
    declarations: [
        SearchInputComponent
    ]
})
export class SearchInputModule {
}
