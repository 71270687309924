<div class="flex flex-col gap-8 text-center justify-center items-center min-w-[40vw]">

    <mat-progress-spinner mode="determinate" diameter="80" [color]="error ? 'warn' : 'primary'" [value]="progress"> </mat-progress-spinner>

    <span [ngClass]="error ? 'text-error' : 'text-primary'">{{ timerValue() }}s - {{ message }}</span>
    <mat-expansion-panel [expanded]="false" class="w-full">
        <mat-expansion-panel-header>
            Histórico
        </mat-expansion-panel-header>
        <div>
            <div *ngFor="let hist of historyList; let last = last" class="text-left flex gap-x-2 font-mono">
                <span *ngIf="!last" class="min-w-[1.75rem] text-primary" [innerHTML]="hist.time + 's'"></span>
                <span *ngIf="!last" [ngClass]="{'text-error': hist.error}" [innerHTML]="hist.message"></span>
            </div>
        </div>
    </mat-expansion-panel>

</div>



