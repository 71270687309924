<div class="flex flex-row w-full">

    <div class="py-2.5 px-5 big-icon flex flex-row justify-center items-center content-center border-l-8" [ngClass]="'border-' + data.type">
        <mat-icon *ngIf="data.type === 'error'" class="text-error">error</mat-icon>
        <mat-icon *ngIf="data.type === 'warn'" class="text-warn">warning</mat-icon>
        <mat-icon *ngIf="data.type === 'success'" class="text-success">check_circle</mat-icon>
        <mat-icon *ngIf="data.type === 'info'" class="text-info">info</mat-icon>
    </div>

    <div class="flex flex-row flex-wrap justify-center items-center content-center py-2.5 w-10/12">
        <h4 *ngIf="data.title" [innerHTML]="data.title | safeHtml" class="w-full m-0 mb-1 leading-5 text-16 text-primary"></h4>
        <h5 *ngIf="data.message" [innerHTML]="data.message | safeHtml" class="w-full m-0 text-slate-500 text-14"></h5>
    </div>

</div>
