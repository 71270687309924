import {Component, OnInit, OnDestroy, ChangeDetectionStrategy, signal, WritableSignal, Input} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {Router, NavigationEnd} from "@angular/router";
import {LogsService} from "src/app/shared/services/logs.service";
import {MatDialog} from "@angular/material/dialog";
import {FiltroEstabsComponent} from "src/app/shared/components/toolbar/filtroEstabs/filtroEstabs.component";
import moment, {Moment} from "moment";
import {PopupNotificacoesComponent} from "src/app/shared/components/toolbar/popup-notificacoes/popup-notificacoes.component";
import {AuthenticatedUserService} from "src/app/shared/services/store/authenticatedUser.service";
import {StorageFiltroEstabelecimentosService} from "src/app/shared/services/localStorage/storage-filtro-estabelecimentos.service";

@Component({
    selector: 'card-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit, OnDestroy {

    public isAdmin: boolean = false;
    public contemFiltro: boolean = this.filtroEstabelecimentosService.get().length > 0;
    public qtdLogsErro: WritableSignal<number> = signal<number>(0);
    private timeLastGetLogs: Moment;
    private popupAberto: WritableSignal<boolean> = signal<boolean>(false);
    private subs: Subscription;

    public loggedUser = this.authenticatedUserService.user;

    @Input() mostraFiltroEstabs: boolean = true;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        sanitizer: DomSanitizer,
        iconRegistry: MatIconRegistry,
        private logsService: LogsService,
        private authenticatedUserService: AuthenticatedUserService,
        private filtroEstabelecimentosService: StorageFiltroEstabelecimentosService,
    ) {
        this.subs = router.events.subscribe((value) => {
            if (value instanceof NavigationEnd) {
                this.carregaPermissaoMenu();
                this.getLogs();
            }
        });
        iconRegistry.addSvgIcon('icon-logo-limber', sanitizer.bypassSecurityTrustResourceUrl('assets/images/L-Limber-branco.svg'));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.carregaPermissaoMenu();
    }

    carregaPermissaoMenu() {
        if (this.authenticatedUserService.isAuthenticated()) {
            let permissoes = this.authenticatedUserService.permissions();
            if (permissoes) {
                this.isAdmin = permissoes.some(perm => perm['administrador'] === 'S');
            }
        }
    }

    async logout() {
        this.authenticatedUserService.clearStorage();
    }

    async getLogs() {
        if (this.authenticatedUserService.isAuthenticated()) {
            if (!this.timeLastGetLogs || moment().diff(this.timeLastGetLogs, 'seconds') > 60) {
                let {
                        notificacoes,
                        logsSync
                    } = await this.logsService.getAll();

                this.qtdLogsErro.set(0);
                notificacoes.forEach(not => {
                    not.tiposError.forEach(tipoErr => {
                        this.qtdLogsErro.update(logs => logs + tipoErr.notificacoes.length);
                    });
                });

                this.timeLastGetLogs = moment();
                let qtdNotificacoesErro = this.qtdLogsErro();
                this.qtdLogsErro.update(logs => logs + logsSync.filter(log => log.status == 'E').length);

                if (qtdNotificacoesErro && !this.popupAberto() && !this.authenticatedUserService.isUserLimber()
                    && !this.router.url.includes('pages/config/notificacoes')
                    && new Date().getTime() >= Number(localStorage.getItem('popupNotficacoes'))
                ) {

                    localStorage.removeItem('popupNotficacoes');
                    this.popupAberto.set(true);
                    let dialogResult = this.dialog.open(PopupNotificacoesComponent, {
                        position: {
                            top: '53px',
                            right: '5px'
                        },
                        panelClass: 'popup-alert',
                        maxWidth: '93vw',
                        maxHeight: '93vh',
                        data: {
                            qtdLogsErro: qtdNotificacoesErro
                        }
                    });

                    dialogResult.afterClosed().subscribe(result => {
                        this.popupAberto.set(false);
                        if (result) {
                            // salvar o horario do inicio do proximo dia
                            localStorage.setItem('popupNotficacoes', (new Date().setHours(0, 0, 0, 0) + 86400000).toString());
                        }
                    });
                }
            }
        }
    }

    async backToMenu() {
        await this.router.navigate(['/home']);
    }

    openDialogEstabs(): void {
        this.dialog.open(FiltroEstabsComponent, {
            width: '800px',
            maxWidth: '95vw',
            maxHeight: '90vh',
        });
    }
}
