import { ErrorHandler, Injectable } from "@angular/core";
import { Utils } from "../utils/utils";
declare var newrelic: any;

@Injectable()
export class NewRelicErrorHandler implements ErrorHandler {

    constructor(private utils: Utils) { }

    handleError(error: any) {
        console.error(error);
        this.sendErrorToNewRelic(error);
        this.verifyNewAppVersion(error);
    }

    private sendErrorToNewRelic(error: any) {
        newrelic.noticeError(error);
    }

    private verifyNewAppVersion(error: any) {
        const menssagemErroCarregarChunk = /Loading chunk \d+ failed/;

        if (menssagemErroCarregarChunk.test(error)) {
            setTimeout(() => window.location.reload(), 3000);
            this.utils.openWarningDialog('Nova versão de site está dísponivel, sua página será atualizada para evitar incompatibilidades!');
        }
    }

}

