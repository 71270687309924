import {ClipboardModule, Clipboard} from '@angular/cdk/clipboard';
import {CommonModule} from '@angular/common';
import {Component, AfterViewInit, Input, ElementRef, Optional, Inject, inject, DestroyRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {Observable, Subscriber, startWith, distinctUntilChanged} from 'rxjs';
import {Utils} from 'src/app/shared/utils/utils';
import {MatButtonModule} from "@angular/material/button";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'limber-json-viewer',
    template: `
    <div [style.max-height]="this.dialogRef ? '100%' : (this.contHeight$ | async)" class="w-full rounded-lg overflow-auto bg-gray">
            <div [style.height]="(this.contHeight$ | async) ? 'auto' : '100vh'" class="p-4 relative">
                <ngx-json-viewer [json]="json" [expanded]="false"></ngx-json-viewer>
                <button matTooltip="Copiar JSON" mat-icon-button class="absolute top-4 right-4 opacity-25 hover:opacity-100"
                        (click)="copy()"><mat-icon>file_copy</mat-icon>
                </button>
            </div>
    </div>
    `,
    styles: [':host { position: relative}'],
    standalone: true,
    imports: [
        CommonModule,
        NgxJsonViewerModule,
        MatDialogModule,
        MatIconModule,
        ClipboardModule,
        MatTooltipModule,
        MatButtonModule
    ]
})
export class JsonViewerComponent implements AfterViewInit {

    private _json: object;

    @Input() set json(value: object | string) {
        if (typeof value === 'string') {
            try {
                value = JSON.parse(value) as object;
            } catch (e) {
                value = { message: value }
            }
        }
        this._json = value
    };
    get json() {
        return this._json;
    }

    destroyRef = inject(DestroyRef)

    contHeight$: Observable<string>;
    resizeElement: Element;
    constructor(
        private clipboard: Clipboard,
        private utils: Utils,
        private elementRef: ElementRef<HTMLElement>,
        @Optional() public dialogRef: MatDialogRef<JsonViewerComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: object,
    ) {
        if (data) {
            this.json = data;
        }
    }

    ngAfterViewInit(): void {
        if (this.dialogRef) {
            this.resizeElement = this.elementRef.nativeElement.parentElement;
        } else {
            this.resizeElement = this.elementRef.nativeElement;
        }
        this.contHeight$ = new Observable((subscriber: Subscriber<string>) => {
            let biggest = 0;
            const resizeObserver = new ResizeObserver(
                (entries: ResizeObserverEntry[]) => {
                    if (entries[0].contentBoxSize[0].blockSize > biggest) {
                        biggest = entries[0].contentBoxSize[0].blockSize;
                        subscriber.next(biggest + 'px');
                    }
                }
            );
            resizeObserver.observe(this.resizeElement);
            return () => resizeObserver.disconnect();
        }).pipe(
            startWith('100%'),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    copy() {
        this.clipboard.copy(JSON.stringify(this.data, undefined, 2));
        this.utils.openSnackBar('Copiado à área de transferência', 'info', 2000);
    }

}
