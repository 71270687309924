import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {LoadingButtonDirective} from './loadingButton.directive';
import {LoadingButtonComponent} from './loadingButton.component';

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    exports: [LoadingButtonDirective, LoadingButtonComponent],
    declarations: [LoadingButtonDirective, LoadingButtonComponent]
})
export class LoadingButtonModule {
}
