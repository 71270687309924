<h2 class="secondary-text">Localizar Terminal</h2>

<div class="flex items-start mt-4">

	<mat-form-field appearance="outline" class="w-full" subscriptSizing="dynamic">
		<mat-label>Terminal</mat-label>
		<input matInput placeholder="Digite o código SN do terminal" [formControl]="terminal" (focus)="naoEncontrado = false">
		<mat-hint *ngIf="naoEncontrado" class="error-color">Nenhum Tick encontrado</mat-hint>
	</mat-form-field>

	<button mat-raised-button color="primary" (click)="buscar()" class="h-[48px] w-[100px] rounded-l-none"
	        [disabled]="terminal.invalid || loading" [loadingButton]="loading">
		Localizar
	</button>

</div>

<div *ngIf="ticks$ | async as ticks" class="max-h-[300px] overflow-y-auto mt-4">

	<div *ngFor="let tick of ticks" class="flex justify-between p-2">
		<div>
			{{ tick.tick_codigo }} - {{ tick.tick_nome }}
			<span class="chip success-bg" *ngIf="tick.ativo"> Ativo </span>
			<span class="chip error-bg" *ngIf="!tick.ativo"> Inativo </span>
		</div>
		<mat-icon matTooltip="Ir para cadastro do Tick Ingressos" class="clickable" color="primary"
		          (click)="goToTick(tick)">open_in_new
		</mat-icon>
	</div>

</div>

