<div class="flex flex-col justify-start items-stretch">

    <div class="basis-full flex flex-row flex-wrap justify-between items-center">

        <h1 class="secondary-text title sm:basis-1/2"> {{ title }} </h1>

        <mat-form-field class="basis-full sm:flex-1 sm:pl-8" appearance="fill">
            <mat-label>Filtro</mat-label>
            <input matInput [formControl]="filtro">
            <mat-icon matSuffix>filter_alt</mat-icon>
        </mat-form-field>

    </div>

    <div class="w-full border-table">

        <mat-progress-bar class="w-full" mode="indeterminate" *ngIf="loading"></mat-progress-bar>

        <cdk-virtual-scroll-viewport itemSize="44" class="h-[400px] relative" minBufferPx="400" maxBufferPx="500" #vel>
            <table [ngClass]="{'hidden': !options?.length}" class="w-full max-sm:min-w-[800px]">

                <thead class="sticky top-0 left-0 right-full h-11 w-full bg-white z-10"
                       [style.top]="-$any(vel)._renderedContentOffset + 'px !important'">
                    <th class="text-center h-11 w-16 p-0">
                        <mat-checkbox color="primary" class="m-auto"
                                      (change)="masterToggle($event.checked)"
                                      [checked]="selection.hasValue() && (filtro.value ? isAllFilteredSelected() : isAllSelected())"
                                      [indeterminate]="selection.hasValue() && (filtro.value ? !isAllFilteredSelected() : !isAllSelected())">
                        </mat-checkbox>
                    </th>
                    <th *ngFor="let row of columns" [style.width]="row.width" class="text-start p-3">
                        {{ row.title }}
                    </th>
                </thead>

                <tbody cdkVirtualScrollingElement>
                    <tr *cdkVirtualFor="let element of filteredData(); let even = even" class="clickable h-11"
                        (click)="toggle(element)" [ngClass]="{'lightpurple-bg': even}">
                        <td class="w-16 p-0 h-max text-center">
                            <mat-checkbox color="primary" class="m-auto"
                                          (click)="$event.stopPropagation()"
                                          (change)="toggle(element)"
                                          [checked]="selection.isSelected(element)">
                            </mat-checkbox>
                        </td>
                        <td *ngFor="let row of columns">
                            <div *ngIf="!row.isHtmlContent && !row.isIcon" class="p-3 pl-5">
                                {{ element[row.dataKeyRef] }}
                            </div>
                            <div *ngIf="row.isHtmlContent && !row.isIcon" [innerHTML]="element[row.dataKeyRef]"
                                 (click)="$event.stopPropagation()">
                            </div>
                            <div *ngIf="row.isIcon && !row.isHtmlContent">
                                <mat-icon [ngClass]="element.icon_class"> {{ element[row.dataKeyRef] }} </mat-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>

            </table>
        </cdk-virtual-scroll-viewport>
    </div>

</div>
