import {NgModule} from '@angular/core';
import {TitleButtonComponent} from "./title-button.component";
import {CommonModule} from "@angular/common";
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
    ],
    exports: [TitleButtonComponent],
    declarations: [TitleButtonComponent]
})
export class TitleButtonModule {
}
