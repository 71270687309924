import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {Router, RouteConfigLoadStart, RouteConfigLoadEnd} from "@angular/router";
import {map} from "rxjs";

@Component({
    selector: 'loading-page',
    standalone: true,
    imports: [CommonModule, MatProgressBarModule],
    template: `
		<div class="fullpage" *ngIf="loadingNewRoute$ | async">
			<mat-progress-bar mode="indeterminate" class="basis-full"></mat-progress-bar>
		</div>
    `,
    styles: [
        `
            .fullpage {
                background: rgba(255, 255, 255, 0.7);
                display: flex;
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                height: 100vh;
                z-index: 1000;
            }
        `
    ]
})
export class LoadingPageComponent {

    public loadingNewRoute$ = this.router.events
        .pipe(
            map((event) => {
                if (event instanceof RouteConfigLoadStart) {
                    return true;
                } else if (event instanceof RouteConfigLoadEnd) {
                    return false;
                }
                return false;
            })
        );

    constructor(private router: Router) {
    }

}
