import {Directive, Input, effect, inject, OnChanges, SimpleChanges} from '@angular/core';
import {AuthenticatedUserService} from "src/app/shared/services/store/authenticatedUser.service";
import {NgIf} from "@angular/common";
import {DestroyedDirective} from "src/app/shared/directives/destroyed/destroyed.directive";
import {Permission} from "src/app/shared/models/auth/Permission";

@Directive({
    selector: '[ifHavePermissionTo]',
    standalone: true,
    hostDirectives: [NgIf, DestroyedDirective]
})
export class HavePermissionToDirective implements OnChanges {

    @Input({required: true}) ifHavePermissionTo: {
        /**
         * ### perm
         * Informe como parâmetro principal o tipo de permissão que o usuário deve ter para que o template seja renderizado
         */
        perm: Permissions,
        /**
         * ### estab
         * #### opcional
         * Informe se quer verificar a permissão por um estabelecimento específico
         */
        estab?: number,
    };

    private readonly ngIfDirective = inject(NgIf);

    constructor(private authenticatedUserService: AuthenticatedUserService) {
        effect(() => {
            this.compare(this.authenticatedUserService.permissions());
        });
    }

    ngOnChanges(_changes: SimpleChanges) {
        this.compare(this.authenticatedUserService.permissions());
    }

    compare(permissions: Permission[]) {
        this.ngIfDirective.ngIf = permissions?.some((perm) => {

            if (!this.ifHavePermissionTo || !perm) {
                return false;
            }

            return perm[this.ifHavePermissionTo.perm] === (this.ifHavePermissionTo.perm === 'visualiza_relatorios_venda' ? 2 : 'S')
                && (this.ifHavePermissionTo.estab ? perm.local_venda === this.ifHavePermissionTo.estab : true);

        }) ?? false;
    }

}

type Permissions = 'visualiza_relatorios_venda'
    | 'altera_cadastro'
    | 'administrador'
    | 'remarcacao'
    | 'pedido_de_cancelamento'
    | 'cancelamento_de_venda'
    | 'integra_venda'
    | 'sac';
