import {Component} from '@angular/core';
import {DestroyedDirective} from "src/app/shared/directives/destroyed/destroyed.directive";
import {AuthenticatedUserService} from "src/app/shared/services/store/authenticatedUser.service";

const _1_HORA = 3600000;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    hostDirectives: [DestroyedDirective],
})
export class AppComponent {

    public production = !window.location.href.includes('testescard.limbersoftware');

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
    ) {
        if (this.authenticatedUserService.isAuthenticated()) {
            setInterval(() => this.authenticatedUserService.updatePermissions(), _1_HORA);
        }
        if (document.getElementById('limber-splash-screen')) {
            document.getElementById('limber-splash-screen').style.display = 'none';
        }
    }

}
