import {Injectable, signal, computed, effect} from '@angular/core';
import {Permission} from 'src/app/shared/models/auth/Permission';
import {API_CONFIG} from 'src/app/app.setings';
import {firstValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LoginResponse} from 'src/app/shared/models/auth/LoginResponse';
import {StoragePermissionsUserService} from 'src/app/shared/services/localStorage/storage-permissions-user.service';
import {StorageUserService} from 'src/app/shared/services/localStorage/storage-user.service';
import {Router} from '@angular/router';
import {StorageTokenService} from 'src/app/shared/services/localStorage/storage-token.service';

const NIVEL_USUARIO_MASTER = 0;
const NIVEL_USUARIO_LIMBER = 5;

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedUserService {

    public readonly user = signal<Partial<LoginResponse>>(this.storageUserService.get());
    public readonly permissions = signal(this.storagePermissionsService.get());

    public readonly isAuthenticated = computed(() => {
        return !!this.user() && !!this.storageTokenService.get();
    });
    public readonly isUserLimber = computed(() => {
        return this.user()?.nivel <= NIVEL_USUARIO_LIMBER;
    });
    public readonly isUserMaster = computed(() => {
        return this.user()?.nivel <= NIVEL_USUARIO_MASTER;
    });

    constructor(
        private router: Router,
        private http: HttpClient,
        private storageUserService: StorageUserService,
        private storageTokenService: StorageTokenService,
        private storagePermissionsService: StoragePermissionsUserService,
    ) {
        // atualiza as permissões no storage sempre que o signal mudar
        effect(() => {
            this.storagePermissionsService.set(this.permissions());
        });
        effect(() => {
            this.storageUserService.set(this.user());
        });
    }

    public async updateSession(loginResponse: LoginResponse) {
        let {token, permissoes, parceiros, ...userInfo} = loginResponse;
        this.storageTokenService.set(token);
        this.user.set(userInfo);
        this.permissions.set(permissoes);
    }

    public clearStorage() {
        this.user.set(null);
        this.permissions.set(null);
        window.localStorage.clear();
        window.sessionStorage.clear();
        this.router.navigate(['./auth/login']).then();
    }

    /**
     * Buscará no novamente no servidor as permissões do usuário e atualizará as variáveis da aplicação
     */
    public async updatePermissions(): Promise<void> {
        if (!this.user()?.codigo) {
            throw new Error('Não é possível atualizar as permissões do usuário se não tiver um usuário logado na plataforma!');
        }
        try {
            let permissions = await firstValueFrom(this.http.get<Permission[]>(`${API_CONFIG.url}auth/permissao/${this.user().codigo}`));
            this.permissions.set(permissions);

        } catch (err) {
            console.log('Erro ao buscar permissões do usuário: ', err);
        }
    }

}
