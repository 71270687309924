import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableWithSelectionComponent} from './table-with-selection.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSortModule} from "@angular/material/sort";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatIconModule,
        MatTableModule,
        MatCheckboxModule,
        MatSortModule,
        MatProgressBarModule,
        ScrollingModule
    ],
    declarations: [TableWithSelectionComponent],
    exports: [
        TableWithSelectionComponent
    ]
})
export class TableWithSelectionModule {
}
