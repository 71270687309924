import {environment} from '../environments/environment';

let url: string, urlPagamentos: string, urlViews: string, urlAgencias: string, urlBot: string;

if (environment.production) {
    url = '/api/';
    urlPagamentos = '/api/pagamentos';
    urlViews = '/api/views';
    urlAgencias = '/api/agencias';
    urlBot = '/api/bot/';

} else {
    url = '/api/';
    urlPagamentos = '/api/pagamentos';
    urlViews = '/api/views';
    urlAgencias = '/api/agencias';
    urlBot = '/api/bot/';
}

export const API_CONFIG = {
    url,
    urlPagamentos,
    urlViews,
    urlAgencias,
    urlBot,
};
