import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export class LimberValidators {

    public static validateCpf = (control: AbstractControl) => {
        let value = control.value
        let strCPF = (value || '').replace(/[^0-9]/g, '');
        let soma = 0;
        let resto;
        if (strCPF === '00000000000') return {cpfInvalid: true};
        for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
        resto = (soma * 10) % 11;
        if ((resto === 10) || (resto === 11)) resto = 0;
        if (resto !== parseInt(strCPF.substring(9, 10), 10)) return {cpfInvalid: true};
        soma = 0;
        for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
        resto = (soma * 10) % 11;
        if ((resto === 10) || (resto === 11)) resto = 0;
        if (resto !== parseInt(strCPF.substring(10, 11), 10)) return {cpfInvalid: true};
        return null;
    }

    public static validateCpfOrCnpj = (control: AbstractControl) => {
        let strCPF = (control.value || '').replace(/[^0-9]/g, '');
        if (strCPF.length > 11) {
            return this.validateCnpj(control);
        } else {
            return this.validateCpf(control);
        }
    };

    public static validateCnpj = (control: AbstractControl) => {
        let cnpj = control.value;
        cnpj = (cnpj || '').replace(/[^\d]+/g, '');

        if (cnpj === '') {
            return {CnpjInvalid: true}
        }

        if (cnpj.length !== 14) {
            return {CnpjInvalid: true}
        }

        if (cnpj === '00000000000000' || cnpj === '11111111111111' || cnpj === '22222222222222' || cnpj === '33333333333333' || cnpj === '44444444444444' || cnpj === '55555555555555' ||
            cnpj === '66666666666666' || cnpj === '77777777777777' || cnpj === '88888888888888' || cnpj === '99999999999999') {
            return {CnpjInvalid: true}
        }

        // Valida DVs LINHA 23 -
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9
            }
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado !== parseInt(digitos.charAt(0), 10)) {
            return {CnpjInvalid: true}
        }

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9
            }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== parseInt(digitos.charAt(1), 10)) {
            return {CnpjInvalid: true}
        }

        return null;
    }

    /**
     * Retona o erro no validator **matchPassword**
     * @param control
     */
    public static checkPasswords = (control: AbstractControl) => {
        let senha = control.get('senha').value;
        let confirmaSenha = control.get('confirmaSenha').value;
        if (senha !== confirmaSenha) {
            control.get('confirmaSenha').setErrors({matchPassword: true});
        } else {
            return null;
        }
    }

    public static isUrl = (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value) {
            let valid = control.value.includes('.') && (control.value.includes('http://') || control.value.includes('https://'));
            if (!valid) {
                return {'invalidUrl': true};
            }
        }
        return null;
    }

    public static jsonString = (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value) {
            try {
                JSON.parse(control.value);
            } catch (e) {
                return {'jsonError': true}
            }
        }
        return null;
    }

    public static slug = (control: AbstractControl) => {
        const slugRegex = /^[a-z0-9\-]*$/
        if (slugRegex.test(control.value)) {
            return null
        }
        return {'invalidSlugError': true}
    }

    public static numeroInteiroPositivo(incluiZero?: boolean) {
        return (control: AbstractControl) => {
            if (control.value) {
                let num = parseFloat(control.value);
                if (isNaN(num)) {
                    return {'notANumber': true};
                }
                if (num < (incluiZero ? 1 : 0)) {
                    return {'numeroNegativo': true};
                }
                if (num % 1 !== 0) {
                    return {'numeroNaoInteiro': true};
                }
        }
            return null;
        };
    }
    /**
     * Função para obrigar ou não um campo de acordo com parâmetro passado
     * @param form - FormGroup à ser mudado
     * @param control - nome do campo FormControl
     * @param require - Booleano se o campo se tonar obrigatório
     */
    public static requireControlByParam(require: boolean): ValidatorFn {
        return (_control: AbstractControl): ValidationErrors | null => {
            if (require && !_control.value) {
                return {'required': true}
            }
            return null;
        }
    }

    /**
     * se erro: retorna **invalidLastName**
     * @param control
     */
    static validaNomeESobrenome: ValidatorFn = (control: AbstractControl) => {
        let isValid = true;
        if (control.value) {
            let nomeDividido = control.value.trim().split(' ');
            isValid = nomeDividido.length > 1 && nomeDividido.every(part => !!part);
        }
        if (!isValid) return {'invalidLastName': true};
        return null;
    }

    /**
     *  se errado: retorna **invalidRg**
     * @param control
     */
    static validaRg: ValidatorFn = (control: AbstractControl | any) => {
        if (RegExp(/\d/).test(control.value)) {
            return null;
        } else {
            return {invalidRg: true};
        }
    }
    public static monthYear = (control: AbstractControl) => {
        const monthYearRegex = /^(0[1-9]|1[0-2])\/\d{4}$/;
        if (!control.value || monthYearRegex.test(control.value)) {
            return null
        }
        return {'invalidMonthYear': true}
    }

    public static validaSenha(config: Partial<SenhaConfig> = {}): ValidatorFn {
        return (control: AbstractControl) => {
            let opts = {
                obrigatorio: true,
                min: 8,
                max: 30,
                maiusculas: true,
                minusculas: true,
                numeros: true,
                simbolos: true,
            }
            Object.assign(opts, config);
            let erros: ValidationErrors = {};
            if (control.value != null && typeof control.value === 'object') {
                throw new TypeError("Tipo da senha invalido")
            }

            if (!opts.obrigatorio && !control.value) {
                return null;
            }

            const senha = control.value?.toString() ?? '';

            if (opts.min != null && (!senha || senha.length < opts.min)) {
                erros["min_char"] = true;
            } else if (opts.max != null && senha.length > opts.max) {
                erros["max_char"] = true;
            }

            if (opts.maiusculas && !/[A-Z]/.test(senha)) {
                erros["maiuscula"] = true;
            }
            if (opts.minusculas && !/[a-z]/.test(senha)) {
                erros["minuscula"] = true;
            }
            if (opts.numeros && !/[0-9]/.test(senha)) {
                erros["numero"] = true;
            }
            if (opts.simbolos && !/[^a-zA-Z0-9]/.test(senha)) {
                erros["simbolo"] = true;
            }

            return erros;
        }
    }
}

export interface SenhaConfig {
    obrigatorio: boolean,
    min: number,
    max: number,
    maiusculas: boolean,
    minusculas: boolean,
    numeros: boolean,
    simbolos: boolean,
}
