import crypto from "crypto-js";
import {CardStorage} from "src/app/shared/services/localStorage/CardStorage";

/**
 * Classe usada para armazenar informações encriptadas no LocalStorage
 */
export abstract class CardCryptoStorage<T> extends CardStorage<T> {

    public abstract cryptoPass: string;
    public override set(data: T) {
        let stringToSave: any = (this.isJson ? JSON.stringify(data) : data);
        localStorage.setItem(this.key, crypto.AES.encrypt(stringToSave, this.cryptoPass).toString());
    }

    public override get(): T {
        let storageData = window.localStorage.getItem(this.key);
        if (!storageData) return this.defaultValue;

        storageData = crypto.AES.decrypt(storageData, this.cryptoPass).toString(crypto.enc.Utf8);
        return (this.isJson ? JSON.parse(storageData) : storageData);
    }

}
