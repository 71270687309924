<button *ngIf="!dropFiles" mat-raised-button color="primary" (click)="openFiles(htmlInputElement)" class="btnInput">
    {{ label }}
</button>

<div *ngIf="dropFiles" (mouseenter)="dragover.set(true)" (mouseleave)="dragover.set(false)"
     (dragover)="$event.preventDefault(); dragover.set(true)" (dragleave)="dragover.set(false)"
     (drop)="dropFile($event); dragover.set(false)" (click)="openFiles(htmlInputElement)"
     class="cursor-pointer transition-colors rounded w-full h-full text-gray-400 p-1 bg-slate-50"
     [ngClass]="{'text-gray-600': dragover()}">
    <div class="relative w-full h-full border-current p-4 border-2 border-solid rounded overflow-hidden flex flex-col items-center justify-center">
        <div class="z-10 flex flex-col items-center rounded-lg p-4" [ngClass]="{'opacity-0' :preview && !dragover(), 'text-gray-50 bg-gray-600 bg-opacity-90 transition-opacity': preview}">
            <mat-icon class="text-[2.5rem] h-14 w-14 text-center">
                upload
            </mat-icon>
            <span class="text-18 text-center">
                {{ label }}
            </span>
        </div>
        <img [ngClass]="{'blur-[2px]': dragover()}" *ngIf="preview" [src]="preview" [style.object-fit]="objectFit ?? 'contain'"
             class="absolute top-0 left-0 w-full h-full transition-all" [alt]="alt">
    </div>
</div>

<div class="hideInput">
    <input type="file" #htmlInputElement
           (change)="loadFile($event.target['files'])"
           [accept]="accept"
           [multiple]="multiple" />
</div>
