<div class="flex">
    <div class="p-5 flex justify-center items-center">
        <mat-icon [ngStyle]="{'font-size': iconSize, 'width': iconSize, 'height': iconSize}">warning</mat-icon>
    </div>
    <div class="ml-2.5">
        <div class="flex flex-col justify-start items-start">
            <h2 class="text-gray mb-2.5">
               Atenção
            </h2>
        </div>
        <div class="flex flex-col justify-start items-start mr-1 mb-4">
           <span class="text-center text-gray">
               Você tem <strong class="text-[large]">{{ data.qtdLogsErro }}</strong> notificações não resolvidas
           </span>
        </div>

        <div class="pb-1 flex justify-end items-center gap-2">
            <button mat-raised-button mat-dialog-close="" routerLink="./pages/config/notificacoes"> Ver notificações</button>
            <button mat-raised-button mat-dialog-close="true"> Lembre-me amanhã</button>
            <button mat-raised-button mat-dialog-close=""> Fechar</button>
        </div>
    </div>
</div>
